h4, h5, h6 {
    color: #0288d1;
}
.littercontainer {
    padding-top: 7px;
    width:98%;
    margin:auto;
}

img.inline-right {
    width:400px;
    float:right;
    margin-left:5px

}

img.inline-left {
    width:200px;
    float:left;
    margin-right: 10px;

}

#puppies img {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.image-label {
    display: inline-block;
    font-weight: bolder;
    margin-top: 5px;
    padding: 5px;
    background-color: transparent;
    box-shadow: 0 0 17px 17px  white inset ;
    border-radius: 20px;
}

@media only screen and (max-width: 900px) {
    .image-label {
        box-shadow: 0 0 17px 11px  white inset ;
    }
  }

  @media only screen and (max-width: 600px) {
    img.inline-right {
        width: 250px;
    }
  }