nav {
    background: transparent;
    height: 32px;
}
.nav-wrapper {
    height: 32px;
    line-height: 32px;
}
#breadcrumb .nav-wrapper {
    margin-left: 3%;
}
.breadcrumb {
    font-size: 14px;
}

.is-sibling {
    display:inline-flex;
    padding-left: 3%;
    padding-right: 3px;
    color:lightgrey;
}

a.has-sibling {
    -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

a.has-sibling:hover .is-sibling {
    display: inline-flex;;
    background-color: #0288d1;

}
