
*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
 .pedigree {
    display: inline-block;


    min-width: 870px;
    max-width: 870px;
    padding: 5px;
    margin: 0;
    color: rgb(189, 130, 20);
    font: 12px;
    /* background: #2e6ba7; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  #wrapper {

    position: relative;
    margin-bottom: 5px;

  }
  
  .branch {
    position: relative;
    margin-left: 210px;
  }
  .branch:before {
    content: "";
    width: 9px;
    border-top: 1px solid #0288d1;
    position: absolute;
    left: -26px;
    top: 50%;
    margin-top: 1px;
  }
  
  .entry {
    position: relative;
    min-height: 75px;
  }
  .entry:before {
    content: "";
    height: 100%;
    border-left: 1px solid #0288d1;
    position: absolute;
    left: -17px;
  }
  .entry:after {
    content: "";
    width: 15px;
    border-top: 1px solid #0288d1;
    position: absolute;
    left: -15px;
    top: 50%;
    margin-top: 1px;
  }
  .entry:first-child:before {
    width: 10px;
    height: 50%;
    top: 50%;
    margin-top: 2px;
    border-radius: 10px 0 0 0;
  }
  .entry:first-child:after {
    height: 10px;
    border-radius: 10px 0 0 0;
  }
  .entry:last-child:before {
    width: 10px;
    height: 50%;
    border-radius: 0 0 0 10px;
  }
  .entry:last-child:after {
    height: 10px;
    border-top: none;
    border-bottom: 1px solid #0288d1;
    border-radius: 0 0 0 10px;
    margin-top: -9px;
  }
  
  .label {
    display: block;
    min-height:60px;
    width: 185px;
    padding: 5px 10px;
    line-height: 20px;
    text-align: center;
    border: 1px solid #0288d1;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -15px;
  }

  @media only screen and (max-width: 600px) {
    .pedigree {
        overflow-x: scroll;
        max-width: 300px;
        min-width: 300px;
    }
  }

  @media only screen and (min-width: 601px)  and (max-width: 900px) {
    .pedigree {
        overflow-x: scroll;
        max-width: 725px;
        min-width: 725px;
    }
  }
