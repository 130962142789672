.each-slide-effect > div {
    display: flex;
    align-items:top;
    justify-content: left;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 300px;
  }
  .card {
    margin-bottom: 0px;
  }
  
  .hangingindent {
    padding-left: 22px ;
    text-indent: -22px ;
  }
  
  img.inline-right {
    width:400px;
    float:right;
    margin-left:5px

}

img.inline-left {
    width:200px;
    float:left;
    margin-right: 10px;

}

img {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: transparent;
    text-align: center;
  }

  #about-page.page-header {
    background-image: url(../../../public/img/about.jpg);
    height: 300px;
  }
  #about-page {
    padding-top: 50px;
    color: white;
  }
  #about-page section p {
    text-align: justify;
        font-size: 20px;
  }

  #about-page .header-content .card {
    background-color: rgb(189, 130, 20, .60);
    padding: 3%;
  }

  @media only screen and (max-width: 600px) {
    img.inline-right {
        width: 250px;
    }
  }