

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 0px;
}



.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  White-space: nowrap;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  height: 32px;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
}

@media (hover: hover) {
.menu-items button:hover {
  background-color:  rgba(0, 0, 0, 0.1);
}
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: rgba(2, 136, 209, .5);

  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.child_li {
  min-width: 100px;
}