.dog-details {
    margin-top: 10px;
    font-size: smaller;
}
table {
    width: 100%;
}
td {
    width:60%
;    padding: 5px 5px;
}
.key-column {
    text-align: right;
}